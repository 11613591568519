<template>
     <div class="preloader wrap">
          <svg viewBox="0 0 366 70" fill="none" xmlns="http://www.w3.org/2000/svg">
               <g>
                    <path
                         d="M37.7273 19.4143C42.9188 19.4098 47.8392 22.0178 50.575 25.6732C50.7115 25.8036 50.7115 25.8036 50.8486 25.9339C50.9851 26.0647 51.1217 26.195 51.1218 26.3259C52.8999 28.5449 55.7701 29.8488 58.9125 29.846C64.3774 29.8413 68.8819 25.5264 68.8774 20.3013C68.8752 17.8194 67.9172 15.5998 66.276 13.903C59.4378 5.81016 51.2367 1.11452 37.5744 1.12643C16.1249 1.14513 0.016318 15.3976 0.0337398 35.3834C0.0513898 55.631 16.1848 69.8549 37.7712 69.8361C51.57 69.824 59.6266 65.2451 66.4509 57.924C67.9526 56.2245 68.9069 54.1337 68.9048 51.7827C68.9003 46.5576 64.5247 42.3813 59.0599 42.3861C56.0544 42.3887 53.4598 43.6969 51.6854 45.6581C48.4092 48.796 44.7231 51.8038 37.892 51.8097C28.0553 51.8183 22.4481 45.0304 22.4397 35.3638C22.2947 26.0897 27.7537 19.423 37.7273 19.4143Z"
                    />
               </g>
               <g>
                    <path
                         d="M130.762 5.35746C127.345 3.66224 125.022 3.27243 119.967 3.66867C110.951 4.46019 103.988 10.4754 102.76 11.9133L102.761 12.3052C102.76 12.1747 102.76 12.0438 102.76 11.9133C102.346 6.42725 97.5601 1.99 91.685 1.99512C85.5374 2.00048 80.623 6.70755 80.4915 12.455L80.5322 59.089C80.6736 64.8367 85.5962 69.4043 91.7437 69.3989C97.8917 69.3936 102.806 64.6865 102.938 58.939L102.927 46.2679C102.912 29.0253 113.428 24.5747 119.302 24.4391C120.805 24.4378 124.768 25.8712 127.91 25.3458C134.057 24.2954 138.014 18.675 136.916 12.9286C136.093 9.53289 133.769 6.79169 130.762 5.35746Z"
                    />
               </g>

               <g>
                    <path
                         d="M179.709 52.2088C189.409 52.2004 195.141 45.403 195.269 35.6059C195.26 25.678 189.38 19.0212 179.68 19.0297C169.98 19.0381 163.975 25.7053 163.983 35.6332C163.992 45.2993 170.009 52.2173 179.709 52.2088ZM179.664 1.00302C200.977 0.984446 217.521 15.3389 217.539 35.4556C217.556 55.4418 201.037 69.6942 179.724 69.7128C158.411 69.7314 141.731 55.5079 141.714 35.5217C141.696 15.405 158.351 1.0216 179.664 1.00302Z"
                    />
               </g>

               <g>
                    <path
                         d="M266.721 19.2161C271.913 19.2115 276.833 21.8196 279.569 25.475C279.706 25.6053 279.706 25.6053 279.842 25.7357C279.979 25.8664 280.116 25.9968 280.116 26.1277C281.894 28.3466 284.764 29.6505 287.906 29.6478C293.371 29.643 297.876 25.3281 297.872 20.103C297.869 17.6212 296.911 15.4015 295.27 13.7047C288.432 5.61192 280.231 0.916278 266.705 0.928068C245.256 0.946766 229.01 15.1993 229.028 35.1851C229.046 55.4327 245.179 69.6566 266.765 69.6378C280.564 69.6258 288.62 65.0469 295.445 57.7258C296.946 56.0263 297.901 53.9354 297.899 51.5844C297.895 46.3594 293.519 42.1831 288.054 42.1879C285.049 42.1905 282.454 43.4987 280.68 45.4598C277.403 48.5977 273.717 51.6055 266.749 51.6116C256.913 51.6202 251.305 44.8322 251.297 35.1657C251.425 25.8913 256.884 19.2246 266.721 19.2161Z"
                    />
               </g>

               <g>
                    <path
                         d="M343.36 26.986C353.335 29.1982 365.908 33.6283 365.92 47.7362C365.931 59.7537 359.382 69.6873 337.659 69.7062C324.407 69.7178 315.796 65.8065 309.78 60.7172C309.643 60.5868 309.507 60.456 309.37 60.3257C309.097 60.195 308.96 59.9342 308.823 59.8035C308.687 59.6731 308.549 59.4119 308.413 59.2815C308.139 59.0204 308.003 58.7592 307.865 58.4983C307.865 58.4983 307.729 58.3676 307.729 58.2371C305.95 55.1036 306.356 51.1845 309.087 48.5694C312.363 45.301 317.827 45.1654 321.245 48.167L321.109 48.0366C325.484 51.2983 330.677 53.2534 336.278 53.2485C343.383 53.2423 345.704 51.9339 345.701 49.1907C345.698 45.5329 341.326 45.5367 330.121 42.8037C320.692 40.4605 310.168 35.5059 310.156 21.7898C310.143 6.89824 322.024 0.879138 336.779 0.866275C348.119 0.856391 356.182 4.37624 361.788 9.8578C363.566 11.2931 364.661 13.5131 364.663 15.864C364.667 20.4359 360.845 24.097 356.063 24.1012C353.74 24.1032 351.69 23.3214 350.186 21.8858C346.905 19.2759 342.121 16.9287 336.93 16.9332C332.011 16.9375 329.553 18.246 329.556 20.8588C329.559 24.5161 333.931 25.0351 343.36 26.986Z"
                    />
               </g>
          </svg>
     </div>
</template>

<script setup>
import { reactive, ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import { defineEmits } from "vue";
import { gsap, Back } from "gsap";
const emit = defineEmits(["fadeOut"]);
const textcolArray = reactive(["#141414", "white", "#D7BCE5", "#FFA9AA", "#0C3139", "#141414", "white"]);
const colors = reactive(["#f1f1f1", "#39BC42", "#4A3ECD", "#12203B", "#C7ECF3", "#FFBC01", "#226EDD"]);
let i = 1;
const textcol = ref("#141414");
onMounted(() => {
     const loader = document.querySelector(".preloader");
     const text = document.querySelectorAll(".preloader svg path");

     const animate = () => {
          setTimeout(() => {
               requestAnimationFrame(animate);
               loader.style.background = colors[i];
               text.forEach((item) => {
                    item.style.fill = textcolArray[i];
               });
               i++;

               if (i > textcolArray.length) {
                    emit("fadeOut");
                    return;
               }
          }, 250);
     };

     window.addEventListener("DOMContentLoaded", () => {
          gsap.from(".preloader svg g", {
               stagger: 0.16,
               delay: 1,
               yPercent: 150,
               duration: 0.5,
               ease: Back.easeInOut.config(1.7),
               autoAlpha: 0,
          })
               .then((r) => {
                    return r;
               })
               .then(() => {
                    requestAnimationFrame(animate);
               });
     });
});
</script>

<style lang="scss">
.wrap {
     position: fixed;
     top: 0px;
     background: #f1f1f1;
     z-index: 100;
     bottom: 0px;
     left: 0px;
     right: 0px;
     height: 100vh;
     @include flex(center, center);
     svg {
          overflow: hidden;
          width: 15rem;
          g {
               path {
                    fill: #141414;
               }
          }
          @include media("<=phone-tab") {
               width: 10rem;
          }
     }
}
</style>
